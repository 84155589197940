import React, { useCallback } from "react";

import { useDispatch } from "react-redux";

import AboutCompanySection from "@/src/components/container/LandingPageContainer/components/AboutCompanySection";
import FeaturedDiscussionSection from "@/src/components/container/LandingPageContainer/components/FeaturedDiscussionSection";
import FeaturedSpacesSection from "@/src/components/container/LandingPageContainer/components/FeaturedSpaceSection";
import SemblyDifferenceSection from "@/src/components/container/LandingPageContainer/components/SemblyDifferenceSection";
import TitleSection from "@/src/components/container/LandingPageContainer/components/TitleSection";
import { MAIN_SECTION_ID } from "@/src/components/container/LandingPageContainer/config";
import type { FeaturedSpacesAndContents } from "@/src/components/container/LandingPageContainer/types";
import LeftBackground from "@/src/components/sembly-ui/core/BaseComponent/Illustration/LandingPage/LeftBackground";
import RightBackground from "@/src/components/sembly-ui/core/BaseComponent/Illustration/LandingPage/RightBackground";
import TutorialCardDialog from "@/src/domains/user/components/TutorialCard/TutorialCardDialog";
import { useTrackPageVisitNonAuth } from "@/src/hooks/internal-tracking/useGenericTracking";
import { slice as signupDialogSlice } from "@/src/stores/signupDialog";

interface Props {
  featuredSpacesAndContents: FeaturedSpacesAndContents;
}

const NewLandingPageContainer: React.FC<Props> = ({ featuredSpacesAndContents }) => {
  useTrackPageVisitNonAuth();
  const dispatch = useDispatch();

  const openSignupDialog = useCallback(() => {
    dispatch(signupDialogSlice.actions.openSignupDialog());
  }, [dispatch]);

  return (
    <>
      <div className="relative mt-[48px] pt-0 md:mt-[56px] md:pt-12">
        <LeftBackground className="absolute left-0  top-0 hidden max-h-full md:block" />
        <RightBackground className="absolute right-0 top-[226px] hidden max-h-full md:block" />

        <div className="relative mx-auto w-full max-w-[852px] pb-20 text-center" id={MAIN_SECTION_ID}>
          <TitleSection />
          {featuredSpacesAndContents && (
            <>
              <FeaturedSpacesSection
                handleStartOwnSpace={openSignupDialog}
                spaces={featuredSpacesAndContents?.spaces}
              />
              <FeaturedDiscussionSection
                nodeMap={featuredSpacesAndContents?.nodeMap}
                featuredNodeIds={featuredSpacesAndContents?.featuredNodeIds}
              />
            </>
          )}
        </div>
        <SemblyDifferenceSection />
        <AboutCompanySection />
      </div>

      <TutorialCardDialog />
    </>
  );
};

NewLandingPageContainer.displayName = "NewLandingPageContainer";

export default NewLandingPageContainer;
