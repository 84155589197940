import { useCallback } from "react";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import SpaceLabelTag from "@/src/components/container/LandingPageContainer/components/SpaceLabelTag";
import type { FeaturedSpace } from "@/src/components/container/LandingPageContainer/types";
import LabelTooltip from "@/src/components/sembly-ui/components/LabelTooltip/LabelTooltip";
import SpaceIcon from "@/src/components/sembly-ui/components/SpaceIcon/SpaceIcon";
import Tooltip from "@/src/components/sembly-ui/components/Tooltip/Tooltip";
import { ArrowRightFillIcon, StarOutlineIcon, UsersIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { ROUTER_PATH_SPACE_WITH_PRESENTATION, SPACE_PRESENTATION } from "@/src/constants/AppConstant";
import { SPACE_PUBLICATION_STATE, SPACE_STATE, SPACE_TYPE } from "@/src/domains/space/constants/Space";
import { cn } from "@/src/utils/cn";
import parseTemplateString from "@/src/utils/helpers/parseTemplateString";

const TOOLTIP_SIDE_OFFSET = -100;

interface SpaceCardProps {
  space: FeaturedSpace;
  zigzagClassObject?: { [key: string]: boolean };
}

const FeaturedSpaceCard: React.FC<SpaceCardProps> = ({ space, zigzagClassObject }) => {
  const [t] = useTranslation("landingpage");

  const shouldDisabledRedirectionToSpace =
    space.spaceType === SPACE_TYPE.PRIVATE ||
    (space.spaceType === SPACE_TYPE.SUBSCRIBE && space.spacePublishedState === SPACE_PUBLICATION_STATE.UNPUBLISHED);

  const generateSpaceUrl = useCallback(
    (spaceUrl: string) => {
      if (!shouldDisabledRedirectionToSpace) {
        const url = parseTemplateString(ROUTER_PATH_SPACE_WITH_PRESENTATION, {
          spaceId: spaceUrl,
          presentation: SPACE_PRESENTATION.ALL,
        });
        return url;
      }
    },
    [shouldDisabledRedirectionToSpace],
  );

  const handleMobileClick = useCallback(() => {
    if (!shouldDisabledRedirectionToSpace) {
      window.open(generateSpaceUrl(space.spaceUrl), "_blank");
    }
  }, [generateSpaceUrl, shouldDisabledRedirectionToSpace, space.spaceUrl]);

  return (
    <Tooltip.Provider>
      <LabelTooltip
        showTooltip={shouldDisabledRedirectionToSpace}
        content={t("publicBetaLandingPage.disabledFeaturedSpace")}
        tooltipAlign="center"
        tooltipSide="bottom"
        tooltipSideOffset={TOOLTIP_SIDE_OFFSET}
        openDrawerAdditionalAction={handleMobileClick}
      >
        <div className="sm:h-[157px]">
          <motion.a
            href={generateSpaceUrl(space.spaceUrl)}
            target="_blank"
            rel="noreferrer"
            key={space.spaceId}
            className={cn(
              "mt-12 block rounded-[10px] border border-light-gray-100 bg-white px-12 py-16 shadow-template sm:px-16",
              {
                "cursor-pointer hover:border-medium-purple-60 hover:bg-light-medium-purple-60 hover:shadow-space-card-landingpage":
                  !shouldDisabledRedirectionToSpace,
                "cursor-default": shouldDisabledRedirectionToSpace,
              },
              zigzagClassObject,
            )}
          >
            <div className="flex items-center space-x-8">
              <SpaceIcon
                spaceName={space.spaceName}
                spaceImageUrl={space.spaceIcon || space.spaceIconLarge}
                spaceUrl={space.spaceUrl}
                variant="lg"
              />
              <div>
                <div className="flex items-center space-x-8">
                  <div
                    className={cn("line-clamp-1 max-w-[225px] break-all text-base font-medium text-sembly-gray", {
                      "hover:underline": !shouldDisabledRedirectionToSpace,
                    })}
                  >
                    {space.spaceName}
                  </div>
                  <SpaceLabelTag spaceStatus={space.spaceStatus} spacePublishedState={space.spacePublishedState} />
                </div>
                <div className="mt-2 flex items-center space-x-4">
                  <div className="flex items-center space-x-4 text-xs text-gray-60">
                    <UsersIcon className="h-16 w-16" />
                    <div className="font-medium text-gray-80">
                      {space.stats?.memberCount}{" "}
                      <span className="hidden font-normal text-gray-60 md:inline">
                        {t("publicBetaLandingPage.member", { count: space.stats?.memberCount })}
                      </span>
                    </div>
                  </div>
                  {Boolean(space.stats?.watcherCount) && (
                    <>
                      <div className="text-xs text-gray-60">·</div>
                      <div className="flex items-center space-x-4 text-xs text-gray-60">
                        <StarOutlineIcon className="h-16 w-16" />
                        <div className="font-medium text-gray-80">
                          {space.stats?.watcherCount}{" "}
                          <span className="hidden font-normal text-gray-60 md:inline">
                            {t("publicBetaLandingPage.watching")}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="relative mt-4 bg-inherit text-xs">
              <div className="line-clamp-4 text-left text-gray-60">{space.description?.text}</div>
              <span className="absolute bottom-0 right-0 flex bg-inherit text-gray-60">
                <>
                  ... &nbsp;
                  <span
                    className={cn("font-medium text-medium-purple-100", {
                      "hover:underline": !shouldDisabledRedirectionToSpace,
                    })}
                  >
                    {space.spaceStatus === SPACE_STATE.RECRUITING
                      ? t("publicBetaLandingPage.preview")
                      : t("publicBetaLandingPage.viewSpace")}
                  </span>
                  <ArrowRightFillIcon className="ml-4 h-16 w-16 text-medium-purple-100" />
                </>
              </span>
            </div>
          </motion.a>
        </div>
      </LabelTooltip>
    </Tooltip.Provider>
  );
};

export default FeaturedSpaceCard;
