import type { FunctionComponent } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import { ArrowBigRightIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { APP_NAME, ROUTER_PATH_ABOUT_PAGE_PUBLIC } from "@/src/constants/AppConstant";

const AboutCompanySection: FunctionComponent = () => {
  const [t] = useTranslation("landingpage");

  return (
    <div className="relative flex justify-center py-36 sm:py-[74px]">
      <a href={ROUTER_PATH_ABOUT_PAGE_PUBLIC} className="flex cursor-pointer items-center space-x-4">
        <h3 className="text-lg text-dark-purple-100 hover:underline sm:text-2xl">
          {t("publicFooter.aboutCompany", { app_name: APP_NAME })}
        </h3>
        <ArrowBigRightIcon className="h-24 w-24 text-dark-purple-100 sm:h-32 sm:w-32" />
      </a>
    </div>
  );
};

export default AboutCompanySection;
