import React, { useCallback, useMemo } from "react";

import dynamic from "next/dynamic";
import { useTranslation } from "react-i18next";

import type { FeaturedNodeId } from "@/src/components/container/LandingPageContainer/types";
import BarSkeleton from "@/src/components/sembly-ui/components/BarSkeleton/BarSkeleton";
import { withTooltipProvider } from "@/src/components/sembly-ui/components/Tooltip/Tooltip";
import { LinkButton } from "@/src/components/sembly-ui/core";
import { ROUTER_PATH_SPACE_NODE } from "@/src/constants/AppConstant";
import { generateSnippetUrl } from "@/src/domains/content/components/Toc/TocBreadcrumb/TocBreadcrumbHelper";
import type { NodeObject } from "@/src/domains/content/types/Node";
import { SPACE_TYPE } from "@/src/domains/space/constants/Space";
import { withCacheProvider } from "@/src/hooks/useCache";
import useFlag from "@/src/hooks/useFlag";
import useSpaceNavigation from "@/src/pages/SpaceLandingPage/components/SpacePageCard/hooks/useSpaceNavigation";
import { withSpaceContentPaddingProvider } from "@/src/pages/SpaceLandingPage/hooks/useSpaceContentPadding";
import type { LocalSearchNode } from "@/src/pages/SpaceLandingPage/types/SearchInSpaceOrSectionResponse";
import { getCleanNodeId } from "@/src/pages/SpaceLandingPage/utils/SpaceSectionListHelper";
import parseTemplateString from "@/src/utils/helpers/parseTemplateString";
import { withI18NProvider } from "@/src/utils/providers/I18NProvider";

// Quill not working on SSR
const BaseDiscussionCard = dynamic(
  () => import("@/src/pages/SpaceLandingPage/components/SpacePageCard/BaseDiscussionCard"),
  {
    ssr: false,
    loading: () => (
      <div className="mt-8 w-full rounded-xl border border-gray-25 bg-white px-24 pt-12">
        <BarSkeleton
          style={{
            height: "30px",
          }}
          numBars={3}
        />
      </div>
    ),
  },
);

interface FeaturedDiscussionProps {
  nodeMap: Record<string, LocalSearchNode>;
  featuredNodeIds: FeaturedNodeId[];
}

const LOAD_MORE_DELIMITER = 4;

const FeaturedDiscussionSection = ({ nodeMap = {}, featuredNodeIds = [] }: FeaturedDiscussionProps) => {
  const [t] = useTranslation("landingpage");
  const { flag: isShowMore, toggle: toggleShowMore } = useFlag();
  const { navigateToSpaceSection } = useSpaceNavigation();

  const shownDiscussionIds = useMemo(() => {
    if (isShowMore) {
      return featuredNodeIds;
    } else {
      return featuredNodeIds.slice(0, LOAD_MORE_DELIMITER);
    }
  }, [featuredNodeIds, isShowMore]);

  const handleDiscussionClick = useCallback((nodeId: string, spaceUrl: string, slug: string) => {
    const discussionViewUrl = parseTemplateString(ROUTER_PATH_SPACE_NODE, {
      spaceId: spaceUrl,
      nodeId: getCleanNodeId(nodeId),
      slug: slug,
      presentation: "all",
    });
    window.open(`${window.location.origin}${discussionViewUrl}`, "_blank");
  }, []);

  const handleSectionClick = useCallback(
    (spaceUrl: string, highlightedId?: string) => {
      navigateToSpaceSection({ spaceUrl, highlightedId, withTracking: true, openNewTab: true });
    },
    [navigateToSpaceSection],
  );

  const mapFeaturedContent = useCallback(
    (data: FeaturedNodeId) => {
      let childNodes: NodeObject[] = [];
      if (!data?.rootId) {
        return null;
      }
      const node = nodeMap?.[data.rootId]?.node || {};
      const isRevision = Boolean(nodeMap?.[data.rootId]?.isMatchedOnHistoryOnly);
      if (data.childrenIds) {
        const childNode = data.childrenIds?.map(childId => {
          return { ...(nodeMap?.[childId]?.node || {}), depth: nodeMap?.[childId]?.depth || 1 };
        });
        childNodes = childNode;
      }

      return (
        <div key={`node-${data.rootId}`} className="text-sembly-gray">
          <BaseDiscussionCard
            useDummyActionIcons
            handleCardClick={handleDiscussionClick}
            isRevision={isRevision}
            node={node}
            nodeMap={nodeMap}
            nodeChildren={childNodes}
            breadcrumbSnippetUrl={generateSnippetUrl(node.spaceCanonicalId)}
            onBreadcrumbClick={(highlightedId?: string) => {
              handleSectionClick(node.spaceCanonicalId, highlightedId);
            }}
            spaceType={SPACE_TYPE.COMMUNITY}
            spaceId={node.spaceId}
            isAvatarNotPopover
          />
        </div>
      );
    },
    [handleDiscussionClick, handleSectionClick, nodeMap],
  );

  if (shownDiscussionIds?.length > 0) {
    return (
      <div className="pb-[54px]">
        <div className="mx-auto mt-24 max-w-[660px] select-none text-left">
          {shownDiscussionIds.map(mapFeaturedContent)}
        </div>
        {featuredNodeIds.length > LOAD_MORE_DELIMITER && (
          <div className="py-10">
            <LinkButton size="m" variant="secondary" className="text-gray-60" onClick={toggleShowMore}>
              {isShowMore
                ? t("publicBetaLandingPage.seeLessDiscussions")
                : t("publicBetaLandingPage.loadMoreDiscussions")}
            </LinkButton>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default withTooltipProvider(
  withI18NProvider(withSpaceContentPaddingProvider(withCacheProvider(FeaturedDiscussionSection))),
);
