import type { FunctionComponent } from "react";
import React from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AddIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { colors } from "@/src/components/sembly-ui/core/token";

function createDashedBgImage(strokeColor: string) {
  const encodedStrokeColor = encodeURIComponent(strokeColor);
  // svg generated from https://kovart.github.io/dashed-border-generator/
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='${encodedStrokeColor}' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;
}

const strokeColorDefault = colors.gray[20];
const strokeColorHovered = colors.medium.purple[100];
const initialDashedBgImage = createDashedBgImage(strokeColorDefault);
const hoveredDashedBgImage = createDashedBgImage(strokeColorHovered);

const Wrapper = styled.div.attrs(() => ({
  className:
    "flex h-[144px] w-full cursor-pointer items-center justify-center rounded-[10px] bg-white hover:bg-light-medium-purple-60 md:w-[389px]",
}))`
  background-image: ${initialDashedBgImage};
  &:hover {
    background-image: ${hoveredDashedBgImage};
  }
`;

interface StartOwnSpaceCardProps {
  fullWidth?: boolean;
  handleStartOwnSpace: () => void;
}

const StartOwnSpaceCard: FunctionComponent<StartOwnSpaceCardProps> = ({ fullWidth, handleStartOwnSpace }) => {
  const [t] = useTranslation("landingpage");

  return (
    <div className={clsx({ "flex max-w-[calc(100vw-48px)] justify-center sm:w-[816px]": fullWidth })}>
      <Wrapper onClick={handleStartOwnSpace}>
        <div>
          <div className="flex items-center justify-center space-x-4 text-medium-purple-100">
            <AddIcon className="h-20 w-20" />
            <div className="text-base sm:text-lg">{t("publicBetaLandingPage.startOwnSpace")}</div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default StartOwnSpaceCard;
