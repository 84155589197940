import type { FunctionComponent } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import { BaseSettingUpTag } from "@/src/components/sembly-ui/components/SpaceTypeBadge/BaseSettingUpTagWithTooltip/BaseSettingUpTagWithTooltip";
import { SPACE_PUBLICATION_STATE, SPACE_STATE } from "@/src/domains/space/constants/Space";

interface SpaceLabelTagProps {
  spaceStatus: string;
  spacePublishedState?: string;
}

const SpaceLabelTag: FunctionComponent<SpaceLabelTagProps> = ({ spaceStatus, spacePublishedState }) => {
  const [t] = useTranslation("landingpage");

  if (spaceStatus === SPACE_STATE.RECRUITING || spacePublishedState === SPACE_PUBLICATION_STATE.UNPUBLISHED) {
    return (
      <BaseSettingUpTag colorful className="min-w-[100px]" size="m">
        {t("publicBetaLandingPage.incubating")}
      </BaseSettingUpTag>
    );
  } else {
    return null;
  }
};

export default SpaceLabelTag;
