import React from "react";

const LeftBackground = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="224" height="536" viewBox="0 0 224 536" fill="none" {...props}>
      <g className="mix-blend-multiply">
        <path
          d="M98.0576 411.594C98.0576 398.476 95.5213 385.488 90.5934 373.369C85.6655 361.25 78.4426 350.238 69.3371 340.963C60.2315 331.688 49.4217 324.33 37.5247 319.31C25.6278 314.291 12.8767 311.707 -0.00048978 311.707L-0.000480652 411.594L98.0576 411.594Z"
          fill="#E8DBF7"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M223.054 347.18C216.323 335.91 207.478 326.045 197.026 318.149C186.573 310.252 174.717 304.478 162.135 301.156C149.552 297.834 136.49 297.03 123.693 298.789C110.896 300.548 98.6149 304.836 87.5517 311.408L138.809 397.224L223.054 347.18Z"
          fill="#FFF2DA"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M91.0537 189.547C91.0537 200.322 93.1371 210.991 97.185 220.946C101.233 230.901 107.166 239.946 114.646 247.565C122.125 255.184 131.005 261.228 140.777 265.351C150.55 269.475 161.024 271.597 171.601 271.597V189.547L91.0537 189.547Z"
          fill="#E5F8DE"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M89.2637 285.273C89.2637 277.247 87.6445 269.299 84.4985 261.884C81.3526 254.469 76.7415 247.731 70.9285 242.056C65.1156 236.38 58.2146 231.878 50.6196 228.807C43.0246 225.735 34.8843 224.154 26.6635 224.154L26.6635 285.273L89.2637 285.273Z"
          fill="#FFF7CC"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M57.7842 146.109C51.0007 146.109 44.2836 147.47 38.0164 150.115C31.7493 152.759 26.0548 156.635 21.2582 161.521C16.4615 166.407 12.6566 172.208 10.0606 178.592C7.46469 184.976 6.12858 191.818 6.12858 198.728L57.7842 198.728L57.7842 146.109Z"
          fill="#F3F3F4"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M134.83 89.0586C126.782 89.0586 118.812 90.6734 111.377 93.8107C103.941 96.9481 97.1851 101.547 91.4941 107.344C85.8032 113.141 81.2888 120.023 78.2089 127.597C75.129 135.171 73.5438 143.29 73.5438 151.488L134.83 151.488L134.83 89.0586Z"
          fill="#DBDEEB"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M85.166 66.6055C87.7095 61.0687 89.1575 55.0969 89.4274 49.0313C89.6972 42.9656 88.7836 36.9248 86.7387 31.2537C84.6938 25.5826 81.5577 20.3923 77.5093 15.9792C73.461 11.566 68.5798 8.01644 63.1443 5.53312L43.7758 47.6952L85.166 66.6055Z"
          fill="#E8DBF7"
        />
      </g>
    </svg>
  );
};

export default LeftBackground;
