import type { FunctionComponent } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import chunk from "lodash.chunk";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";

import FeaturedSpaceCard from "@/src/components/container/LandingPageContainer/components/FeaturedSpaceCard";
import StartOwnSpaceCard from "@/src/components/container/LandingPageContainer/components/StartOwnSpaceCard";
import type { FeaturedSpace } from "@/src/components/container/LandingPageContainer/types";
import { CarouselArrowIcon } from "@/src/components/sembly-ui/core/BaseComponent/Icon";
import { screens } from "@/src/components/sembly-ui/core/token";
import useMedia from "@/src/hooks/useMedia";

const SPACES_PER_VIEW = {
  MOBILE: 1,
  DESKTOP: 4,
};
const SLIDE_TO_SHOW = {
  MOBILE: 1,
  DESKTOP: 2,
};
const SLIDE_TO_SCROLL = 1;

interface SpacePlaceholderProps {
  isSpacePlaceholder?: boolean;
}
type CarouselSpaceProps = FeaturedSpace | SpacePlaceholderProps;

interface FeaturedSpaceProps {
  spaces: FeaturedSpace[];
  handleStartOwnSpace: () => void;
}

const FeaturedSpaceSection: FunctionComponent<FeaturedSpaceProps> = ({ spaces = [], handleStartOwnSpace }) => {
  const [t] = useTranslation("landingpage");
  const { matchesMobile, matchesDesktop } = useMedia();

  const carouselRef = useRef<Carousel>(null);
  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(spaces.length + 1 > SPACES_PER_VIEW.DESKTOP);
  const numOfColumnsPerView = matchesMobile ? SLIDE_TO_SHOW.MOBILE : SLIDE_TO_SHOW.DESKTOP;

  // group spaces so it will work on the carousel
  const transformedSpaces = useMemo(() => {
    const spacesWithPlaceholder: CarouselSpaceProps[] = [...spaces, { isSpacePlaceholder: true }];

    if (matchesMobile) {
      return chunk(spacesWithPlaceholder, SPACES_PER_VIEW.MOBILE);
    } else {
      const spacesPerView = chunk(spacesWithPlaceholder, SPACES_PER_VIEW.DESKTOP);
      const carouselSpaces: CarouselSpaceProps[][] = [];
      spacesPerView.forEach(spaces => {
        carouselSpaces.push(...chunk(spaces, numOfColumnsPerView));
      });
      return carouselSpaces;
    }
  }, [matchesMobile, numOfColumnsPerView, spaces]);

  const handlePrevArrowClicked = useCallback(() => {
    if (carouselRef.current) {
      const prevSlide = Math.max(0, carouselRef.current.state.currentSlide - 1);
      carouselRef.current.goToSlide(prevSlide);
    }
  }, []);

  const handleNextArrowClicked = useCallback(() => {
    if (carouselRef.current) {
      const nextSlide = carouselRef.current.state.currentSlide + 1;
      carouselRef.current.goToSlide(nextSlide);
    }
  }, []);

  const handleBeforeSwipe = useCallback(
    (_nextSlide: number, state: { totalItems: number }) => {
      const nextSlide = Math.round(_nextSlide);
      if (nextSlide === 0) {
        setShouldShowLeftArrow(false);
        setShouldShowRightArrow(true);
      } else {
        setShouldShowLeftArrow(true);
        const numberOfDotsToShow = Math.ceil((state.totalItems - numOfColumnsPerView) / SLIDE_TO_SCROLL) + 1; // formula taken from the library
        const isLastSlide = nextSlide === numberOfDotsToShow - 1;
        if (isLastSlide) {
          setShouldShowRightArrow(false);
        } else {
          setShouldShowRightArrow(true);
        }
      }
    },
    [numOfColumnsPerView],
  );

  const carouselResponsiveProps = {
    tablet: {
      breakpoint: { max: Number.MAX_VALUE, min: parseInt(screens.sm) },
      items: SLIDE_TO_SHOW.DESKTOP,
      slidesToSlide: SLIDE_TO_SCROLL,
      partialVisibilityGutter: 20, // spillover for the next part in pixel
    },
    mobile: {
      breakpoint: { max: parseInt(screens.sm), min: 0 },
      items: SLIDE_TO_SHOW.MOBILE,
      slidesToSlide: SLIDE_TO_SCROLL,
      partialVisibilityGutter: 30, // spillover for the next part in pixel
    },
  };

  let spacesSection;
  if (spaces?.length > 0) {
    spacesSection = (
      <Carousel
        ref={carouselRef}
        ssr
        swipeable
        draggable
        partialVisible
        // to give vertical spacing to the dots
        containerClass="pb-28 sm:pb-32"
        // give min height so when card hovered, height change doesn't cause jiggly behavior
        sliderClass="sm:min-h-[272px]"
        responsive={carouselResponsiveProps}
        arrows={false}
        showDots
        customDot={<CustomDot />}
        dotListClass="grid gap-8 !mr-16 sm:!m-auto"
        beforeChange={handleBeforeSwipe}
      >
        {transformedSpaces.map((spaces, index) => {
          return (
            <div className="grid text-left" key={index}>
              {spaces.map((spaceProps, rowIdx) => {
                const zigzagClassObject = {
                  "mr-16": rowIdx % numOfColumnsPerView === 0,
                  "ml-16": rowIdx % numOfColumnsPerView === 1,
                };
                if ((spaceProps as SpacePlaceholderProps).isSpacePlaceholder) {
                  return (
                    <div className={clsx("mt-12 pr-16 sm:pr-0", zigzagClassObject)} key={rowIdx}>
                      <StartOwnSpaceCard handleStartOwnSpace={handleStartOwnSpace} />
                    </div>
                  );
                }

                const space = spaceProps as FeaturedSpace;
                return <FeaturedSpaceCard space={space} zigzagClassObject={zigzagClassObject} key={rowIdx} />;
              })}
            </div>
          );
        })}
      </Carousel>
    );
  }

  const carouselArrowSharedClasses =
    "absolute top-1/2 -mt-20 -translate-y-1/2 transform cursor-pointer text-medium-purple-40 hover:text-medium-purple-80";

  return (
    <>
      <div className="text-base text-gray-80 sm:mb-4 sm:text-xl">
        {t("publicBetaLandingPage.exploreFeaturedSpaces")}
      </div>

      <div className="relative pl-16 md:pl-0">
        {matchesDesktop && shouldShowLeftArrow && (
          <CarouselArrowIcon
            className={clsx("left-[-68px]", carouselArrowSharedClasses)}
            onClick={handlePrevArrowClicked}
          />
        )}
        {spacesSection}
        {matchesDesktop && shouldShowRightArrow && (
          <CarouselArrowIcon
            className={clsx("right-[-68px] rotate-180", carouselArrowSharedClasses)}
            onClick={handleNextArrowClicked}
          />
        )}
      </div>
    </>
  );
};

export default FeaturedSpaceSection;

interface CustomDotProps {
  active?: boolean;
  onClick?: () => void;
  index?: number;
  carouselLength?: number;
}

const CustomDot = ({ active, onClick }: CustomDotProps) => {
  return (
    <button
      className={clsx("h-8 w-8 rounded-full sm:h-12 sm:w-12", {
        "bg-medium-purple-80": active,
        "bg-gray-20 hover:bg-medium-purple-40": !active,
      })}
      onClick={onClick}
    />
  );
};
