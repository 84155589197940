import type { FunctionComponent } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import useMedia from "@/src/hooks/useMedia";

const TitleSection: FunctionComponent = () => {
  const [t] = useTranslation("landingpage");
  const { matchesMobile } = useMedia();

  return (
    <>
      <div className="mx-16 mb-6 pt-42 font-robotoSlab text-2xl font-normal text-sembly-gray sm:mx-0 sm:mb-0 sm:pt-0 sm:text-[46px] sm:leading-[69px]">
        {matchesMobile ? (
          <>
            {t("publicBetaLandingPage.title.mobile1")} <br />
            {t("publicBetaLandingPage.title.mobile2")}
          </>
        ) : (
          t("publicBetaLandingPage.title.desktop")
        )}
      </div>
      <div className="mb-36 px-16 text-xl sm:px-60">{t("publicBetaLandingPage.subtitle")}</div>
    </>
  );
};

export default TitleSection;
