import type { ComponentProps, FunctionComponent, MutableRefObject } from "react";
import React, { useCallback, useEffect, useRef } from "react";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const ContainerWrapper = ({ children, className }: ComponentProps<"div">) => {
  return (
    <section className={"flex bg-white py-60 even:bg-light-medium-purple-40 sm:h-[428px]"}>
      <div
        className={clsx(
          "mx-auto flex max-w-[1040px] flex-col px-24 text-sembly-gray sm:h-[284px] sm:flex-row sm:gap-[43px] sm:px-0",
          className,
        )}
      >
        {children}
      </div>
    </section>
  );
};

const Video = ({ src }: { src: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (videoRef?.current) {
      if (inView) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [inView, videoRef]);

  const setRefs = useCallback(
    (node: HTMLVideoElement) => {
      // Ref's from useRef needs to have the node assigned to `current`
      const videoElement = node;
      (videoRef as MutableRefObject<HTMLVideoElement>).current = videoElement;

      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(videoElement);
    },
    [inViewRef],
  );

  return (
    <div className="h-full flex-1 items-center justify-center">
      <video
        ref={setRefs}
        controls={false}
        playsInline
        loop={true}
        muted
        className="aspect-square w-full max-w-full rounded-md sm:aspect-video"
        src={src}
      />
    </div>
  );
};

const ContentWrapper = (props: ComponentProps<"div">) => {
  return <div className="flex flex-col gap-16 text-center sm:text-left" {...props} />;
};

const SemblyDifferenceSection: FunctionComponent = () => {
  const [t] = useTranslation("landingpage");
  return (
    <div className="pt-60">
      <div className="mb-20 text-center text-xl text-gray-80">
        {t("publicBetaLandingPage.howToParticipate.heading")}
      </div>
      <ContainerWrapper>
        <div className="flex-1">
          <Video src="https://ik.imagekit.io/8gceidoqid/tutorial-index-card/tutorial-index-card-2024/Tutorial%20Card%202%20-%20Structured%20Collaboration%20-%20R3_5GbyeHnJd.mp4?updatedAt=1711698404931" />
        </div>
        <div className="flex flex-1 items-center sm:justify-end sm:pl-80  ">
          <ContentWrapper>
            <div className="font-robotoSlab text-2xl font-medium ">
              {t("publicBetaLandingPage.howToParticipate.title1")}
            </div>
            <div className="text-base sm:text-lg  ">{t("publicBetaLandingPage.howToParticipate.description1")}</div>
          </ContentWrapper>
        </div>
      </ContainerWrapper>
      <ContainerWrapper className="!flex-col-reverse sm:!flex-row">
        <div className="flex flex-1 items-center sm:justify-start sm:pr-80 ">
          <ContentWrapper>
            <div className="font-robotoSlab text-2xl font-medium ">
              {t("publicBetaLandingPage.howToParticipate.title2")}
            </div>
            <div className="text-base sm:text-lg ">{t("publicBetaLandingPage.howToParticipate.description2")}</div>
          </ContentWrapper>
        </div>
        <div className="flex-1 place-self-start sm:self-auto">
          <Video src="https://ik.imagekit.io/8gceidoqid/landing-page/Versatile%20Organisation%20Systems%20-%20R5%20(lightened%20outline)_v9IijZM9i.mp4?tr=orig&updatedAt=1721727519548" />
        </div>
      </ContainerWrapper>
      <ContainerWrapper>
        <div className="flex-1">
          <Video src="https://ik.imagekit.io/8gceidoqid/tutorial-index-card/tutorial-index-card-2024/Tutorial%20Card%203%20-%20Collective%20Governance%20-%20R2(1)_Wtgl57L0s.mp4?updatedAt=1711698404968" />
        </div>
        <div className="flex flex-1 items-center sm:justify-end sm:pl-80">
          <ContentWrapper>
            <div className="font-robotoSlab text-2xl font-medium">
              {t("publicBetaLandingPage.howToParticipate.title3")}
            </div>
            <div className="text-base sm:text-lg ">{t("publicBetaLandingPage.howToParticipate.description3")}</div>
          </ContentWrapper>
        </div>
      </ContainerWrapper>
    </div>
  );
};

export default SemblyDifferenceSection;
