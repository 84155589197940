import React from "react";

const RightBackground = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="202" height="465" viewBox="0 0 202 465" fill="none" {...props}>
      <g className="mix-blend-multiply">
        <path
          d="M146.753 465C146.753 453.359 144.507 441.831 140.144 431.076C135.782 420.321 129.387 410.548 121.325 402.316C113.263 394.085 103.693 387.555 93.1599 383.1C82.6269 378.645 71.3376 376.352 59.9368 376.352L59.9368 465L146.753 465Z"
          fill="#E8DBF7"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M113.605 182.172C113.605 191.908 115.484 201.549 119.133 210.545C122.782 219.54 128.13 227.714 134.872 234.598C141.615 241.483 149.619 246.944 158.429 250.67C167.238 254.396 176.68 256.314 186.215 256.314V182.172L113.605 182.172Z"
          fill="#FFF2DA"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M137.282 352.684C137.282 361.362 138.956 369.955 142.209 377.973C145.461 385.99 150.228 393.275 156.238 399.411C162.247 405.548 169.382 410.415 177.233 413.736C185.085 417.057 193.501 418.767 202 418.767V352.684L137.282 352.684Z"
          fill="#E0F6F8"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M10.2148 31.5586C10.2148 42.6709 12.3583 53.6744 16.5229 63.9408C20.6875 74.2072 26.7917 83.5355 34.4869 91.3931C42.1821 99.2506 51.3176 105.484 61.3718 109.736C71.4261 113.989 82.2022 116.177 93.0848 116.177V31.5586L10.2148 31.5586Z"
          fill="#E5F8DE"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M118.341 112.824C125.596 112.824 132.78 111.365 139.483 108.53C146.186 105.695 152.276 101.54 157.406 96.3014C162.536 91.063 166.606 84.8441 169.382 77.9999C172.158 71.1556 173.587 63.8199 173.587 56.4117L118.341 56.4117L118.341 112.824Z"
          fill="#E8DBF7"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M97.5371 160.297C91.3894 156.446 84.5268 153.869 77.3411 152.713C70.1555 151.557 62.7875 151.845 55.6579 153.561C48.5282 155.276 41.7766 158.386 35.7884 162.711C29.8002 167.037 24.6927 172.495 20.7576 178.772L67.5718 208.099L97.5371 160.297Z"
          fill="#F3F3F4"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M110.448 344.922C110.448 330.211 107.611 315.645 102.098 302.054C96.5844 288.463 88.5036 276.114 78.3167 265.712C68.1297 255.311 56.036 247.059 42.7261 241.43C29.4162 235.8 15.1507 232.903 0.744202 232.903L0.744202 344.922L110.448 344.922Z"
          fill="#FFF7CC"
        />
      </g>
      <g className="mix-blend-multiply">
        <path
          d="M169.643 271.957C162.491 271.957 155.41 273.395 148.803 276.19C142.196 278.984 136.192 283.08 131.135 288.244C126.079 293.407 122.067 299.537 119.331 306.284C116.594 313.03 115.185 320.261 115.185 327.564L169.643 327.564L169.643 271.957Z"
          fill="#DBDEEB"
        />
      </g>
    </svg>
  );
};

export default RightBackground;
