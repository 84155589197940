import type { NextPage } from "next";

import SPAApp from "@/src/components/SPAApp";
import NewLandingPageContainer from "@/src/components/container/LandingPageContainer/NewLandingPageContainer";
import type { FeaturedSpacesAndContents } from "@/src/components/container/LandingPageContainer/types";
import PublicPageWrapper from "@/src/components/container/PublicPageWrapper/PublicPageWrapper";
import MetaTags from "@/src/utils/SEO/MetaTags";
import LandingPageServerSideProps from "@/src/utils/ServerUtil/LandingPageServerSideProps";

interface IndexProps {
  isLoggedIn: boolean;
  featuredSpacesAndContents: FeaturedSpacesAndContents;
}

const Page: NextPage<IndexProps> = ({ isLoggedIn, featuredSpacesAndContents }) => {
  return (
    <>
      <MetaTags />

      {isLoggedIn && <SPAApp />}
      {!isLoggedIn && (
        <>
          <PublicPageWrapper className="bg-white">
            <NewLandingPageContainer featuredSpacesAndContents={featuredSpacesAndContents} />
          </PublicPageWrapper>
        </>
      )}
    </>
  );
};

export const getServerSideProps = LandingPageServerSideProps;

export default Page;
